import React, {
  forwardRef,
  useEffect
} from "react"
import {
  Col,
  Row
} from "react-bootstrap"
import {
  DateConvert
} from "utilities"

import Logo from "config"
import TTDBTS from "assets/image/ttdBTS.png"
import TTD2 from "assets/image/TTD2.png"
import TTD3 from "assets/image/TTD3.png"

const PrintSalesOrder = forwardRef((props, ref) => {
  let id = props.id
  let data = props.dataPrint
  const Table = props.table

  const InfoItemVertical = ({ title, value }) => (
    <div className='mb-2'>
      <small style={{ fontSize: 12 }} className="text-secondary">{title}</small>
      <div style={{ fontSize: 14 }}>{value}</div>
    </div>
  )

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <img src={Logo.LOGO} alt="" width={110} />
      <div style={{ marginLeft: 30 }}>
        <div className="font-weight-bold">PT. PUSPA BERAS BALI</div>
        <div>Pengeragoan Dauh Tukad, Pengeragoan, Pekutatan, Jembrana, Bali.</div>
        <div>Telp. : +62 819-0416-8666</div>
        <div>Email : puspaberasbali@gmail.com</div>
      </div>
    </div>
  )

  const TTDSection = () => {
    const TTDItems = ({ title, value }) => (
      <Col className="text-center">
        <div>{title}</div>
        <div style={{ marginTop: 100 }}>
          <hr style={{ maxWidth: 250, borderColor: "black" }} />
          {value}
        </div>
      </Col>
    )

    return (
      <Row className="mt-5 pt-3 d-flex justify-content-around">
        <TTDItems
          title="Penerima,"
          value=""
        />
        <TTDItems
          title="Hormat Kami,"
          value="PT. PUSPA BERAS BALI"
        />
      </Row>
    )
  }

  useEffect(() => { }, [id])

  return (
    <div className="container my-5 px-5" ref={ref}>
      <KopSuratSection />
      <div className="row pt-5">
        <div className="col">
          <InfoItemVertical title="Tanggal Sales Order" value={DateConvert(new Date(data.tgl_sales_order)).detail} />
          <InfoItemVertical title="No. Sales Order" value={data.no_sales_order} />
          <InfoItemVertical title="Batas Waktu Pengiriman" value={DateConvert(new Date(data.batas_waktu)).detail} />
        </div>
        <div className="col">
          <InfoItemVertical title="Sales" value={data.nama_sales} />
          <InfoItemVertical title="Customer" value={data.nama_customer} />
        </div>
        <div className="col">
          <InfoItemVertical title="Alamat Pengiriman" value={data.alamat_pengiriman} />
        </div>
      </div>
      <div className="mt-4" />
      <Table />
      <div className="col">
        <InfoItemVertical title="Catatan Sales Order" value={data.catatan} />
      </div>
      <TTDSection />
    </div >
  )
})

export default PrintSalesOrder
